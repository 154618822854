<template>
    <div>
        <Slider ratio="10by1" :image="$options.filters.cdnUrl(firstSlide.image)" :title="$t('menu.account')" />

        <div class="theme-light bg-white pagebg">
            <Articles :articles="articles" />

            <div class="c w-m section-margin-top-small">
                <div class="flex -space-between -align-center">
                    <h2 class="color-accent">{{ $t('login.profile') }}</h2>
                    <router-link to="/logout" class="button">{{ $t('login.actions.logout') }}</router-link>
                </div>

                <Form class="mt-xl" formname="Userform" buttonClass="bg-white" :data="formdata" :translations="{
                    submit: $t('login.actions.save'),
                    submit_success: $t('login.toasts.profile_update_success'),
                    submit_error: $t('login.toasts.profile_update_error'),
                    password_too_short: $t('login.toasts.password_too_short'),
                }">
                    <fieldset>
                        <label for="name">{{ $t('contact.name') }}</label>
                        <input type="text" id="name" v-model="formdata.name" autocomplete="name" required />
                    </fieldset>

                    <fieldset>
                        <label for="email">{{ $t('login.fields.email') }}</label>
                        <input type="email" id="email" v-model="formdata.email" autocomplete="email" required />
                    </fieldset>

                    <fieldset>
                        <label for="password">{{ $t('login.fields.password') }}</label>
                        <input type="password" id="password" v-model="formdata.password"
                            :placeholder="'(' + $t('login.fields.unchanged') + ')'" autocomplete="new-password"
                            minlength="8" maxlength="200" />
                    </fieldset>
                </Form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'About',
    components: {
        Articles: () => import('@/components/Articles.vue'),
        Slider: () => import('@/components/Slider.vue'),
        Form: () => import('@/components/ui/Form.vue'),
    },
    data() {
        return {
            articles: [],
            team: [],
            formdata: {
                id: '',
                name: '',
                email: '',
                password: '',
            },
        };
    },

    computed: {
        lang() {
            return this.$store.getters['Language/current'];
        },

        firstSlide() {
            if (this.$store.state.data.slides && this.$store.state.data.slides.length > 0) {
                return this.$store.state.data.slides[0];
            }
            return {};
        },
    },

    mounted() {
        this.$store.dispatch('get', 'items/clientpage').then((r) => {
            this.articles = r;
        });

        const currentUser = this.$store.getters['Auth/user'];
        if (currentUser) {
            this.formdata.id = currentUser.user.id;
            this.formdata.name = currentUser.user.name;
            this.formdata.email = currentUser.user.email;
        }
    },
};
</script>
